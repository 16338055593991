import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AdminNavigationsCreateBlock = () => {
  const navigate = useNavigate();
  const { indicator } = useParams();

  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ image: any }>({
    image: {},
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    const files = (e.target as HTMLInputElement).files;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "image" ? files![0] : value,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (requestProcessing) {
      return;
    }

    try {
      setRequestProcessing(true);
      const data = new FormData();
      data.append("image", formData.image);
      data.append("indicator", indicator!);

      await fetch("https://api.uemurachiropractic.com/create-block.php", {
        method: "POST",
        body: data,
      });

      toast.success("Create success.");
      navigate(-1);
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setRequestProcessing(false);
    }
  };

  return (
    <div className="h-screen py-12 bg-gray-200">
      <form
        onSubmit={handleOnSubmit}
        className="flex flex-col gap-4 max-w-sm mx-auto p-4 bg-white shadow-lg"
      >
        <h1 className="font-bold text-2xl text-center">New Block</h1>
        <input
          id="image"
          name="image"
          type="file"
          className="file-input file-input-bordered w-full"
          onChange={handleInputChange}
        />
        <button type="submit" className="btn btn-primary">
          {requestProcessing ? "Processing..." : "Save"}
        </button>
        <button
          type="button"
          className="btn btn-error"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default AdminNavigationsCreateBlock;
