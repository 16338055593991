import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AdminIndex from "./AdminIndex";
import { ToastContainer } from "react-toastify";
import AdminHome from "./AdminHome";
import AdminLanguages from "./AdminLanguages";
import AdminLanguagesCreate from "./AdminLanguagesCreate";
import AdminNavigations from "./AdminNavigations";
import AdminNavigationsCreateBlock from "./AdminNavigationsCreateBlock";

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Outlet />}>
        <Route index element={<AdminIndex />} />
        <Route path="home" element={<AdminHome />} />
        <Route path="languages">
          <Route index element={<AdminLanguages />} />
          <Route path="create" element={<AdminLanguagesCreate />} />
        </Route>
        <Route path="navigation">
          <Route path=":indicator">
            <Route index element={<AdminNavigations />} />
            <Route path="create" element={<AdminNavigationsCreateBlock />} />
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default App;
