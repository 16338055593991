import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AdminNavigation = () => {
  const navigate = useNavigate();
  const currentURL = window.location.href;

  const [navigations, setNavigations] = useState([]);

  const getNavigations = async () => {
    try {
      if (JSON.parse(localStorage.getItem("navigation")!)) {
        setNavigations(JSON.parse(localStorage.getItem("navigation")!));
        return;
      }

      const response = await fetch(
        "https://api.uemurachiropractic.com/get-navigations.php",
        {
          method: "GET",
        }
      );

      localStorage.setItem("navigation", await response.text());
      setNavigations(JSON.parse(localStorage.getItem("navigation")!));
      console.log(navigations);
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    getNavigations();
  }, []);

  return (
    <div className="flex flex-col gap-2 min-w-60 max-w-60 h-screen p-4 border-r-2 border-black">
      <button
        className={`btn ${currentURL.includes("/home") ? "btn-neutral" : ""}`}
        onClick={() => navigate("/home")}
      >
        Home
      </button>
      <button
        className={`btn ${
          currentURL.includes("/languages") ? "btn-neutral" : ""
        }`}
        onClick={() => navigate("/languages")}
      >
        Languages
      </button>
      {navigations!.map((nav: {}, index: number) => (
        <button
          key={index}
          className={`btn ${
            currentURL.includes(`/navigation/${(nav as any).indicator}`)
              ? "btn-neutral"
              : ""
          }`}
          onClick={() => navigate(`/navigation/${(nav as any).indicator}`)}
        >
          {(nav as any).name} Navigation
        </button>
      ))}

      <button className={`btn btn-error mt-auto`} onClick={() => navigate("/")}>
        Log Out
      </button>
    </div>
  );
};

export default AdminNavigation;
