import React from "react";
import AdminNavigation from "./components/AdminNavigation";

const AdminHome = () => {
  return (
    <div className="flex">
      <AdminNavigation />
      <div className="flex flex-1 h-screen p-4 bg-gray-200 overflow-auto">
        <h1 className="m-auto font-bold text-2xl">WELCOME!</h1>
      </div>
    </div>
  );
};

export default AdminHome;
