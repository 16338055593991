import React, { useEffect, useState } from "react";
import AdminNavigation from "./components/AdminNavigation";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const AdminNavigations = () => {
  const navigate = useNavigate();

  const { indicator } = useParams();
  const [requestUpdateImage, setRequestUpdateImage] = useState<boolean>(false);
  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [languageIndex, setLanguageIndex] = useState(0);
  const [navigationBlocks, setNavigationBlocks] = useState([]);

  const getNavigations = async () => {
    try {
      const response = await fetch(
        `https://api.uemurachiropractic.com/get-navigation-blocks.php?indicator=${indicator}`,
        {
          method: "GET",
        }
      );
      setNavigationBlocks(JSON.parse(await response.text()));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  const [languages, setLanguages] = useState([]);

  const getLanguages = async () => {
    try {
      const response = await fetch(
        "https://api.uemurachiropractic.com/get-languages.php",
        {
          method: "GET",
        }
      );
      setLanguages(JSON.parse(await response.text()));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    blockIndex: number,
    contentIndex: number
  ) => {
    const { name, value } = e.target;
    setNavigationBlocks((prevData) => {
      const updatedBlocks = [...prevData];
      (updatedBlocks[blockIndex] as any).contents[contentIndex][name] = value;
      return updatedBlocks;
    });
  };

  const handleUpdateImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    blockIndex: number
  ) => {
    const files = (e.target as HTMLInputElement).files;

    if (requestUpdateImage) {
      return;
    }

    try {
      setRequestUpdateImage(true);
      const data = new FormData();
      data.append("id", (navigationBlocks[blockIndex] as any).id);
      data.append("image", files![0]);

      await fetch("https://api.uemurachiropractic.com/update-block-image.php", {
        method: "POST",
        body: data,
      });

      toast.success("Update image success.");
      await getNavigations();
      setRequestUpdateImage(false);
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setRequestUpdateImage(false);
    }
  };

  const handleOnSubmit = async (
    e: React.FormEvent,
    blockIndex: number,
    contentIndex: number
  ) => {
    e.preventDefault();

    if (requestProcessing) {
      return;
    }

    try {
      setRequestProcessing(true);
      const data = new FormData();
      data.append(
        "id",
        (navigationBlocks[blockIndex] as any).contents[contentIndex].id
      );
      data.append(
        "indicator",
        (navigationBlocks[blockIndex] as any).contents[contentIndex].indicator
      );
      data.append(
        "title",
        (navigationBlocks[blockIndex] as any).contents[contentIndex].title
      );
      data.append(
        "sub_title",
        (navigationBlocks[blockIndex] as any).contents[contentIndex].sub_title
      );
      data.append(
        "description",
        (navigationBlocks[blockIndex] as any).contents[contentIndex].description
      );
      data.append(
        "button_label",
        (navigationBlocks[blockIndex] as any).contents[contentIndex]
          .button_label
      );
      data.append(
        "button_text",
        (navigationBlocks[blockIndex] as any).contents[contentIndex].button_text
      );
      data.append(
        "button_link",
        (navigationBlocks[blockIndex] as any).contents[contentIndex].button_link
      );

      await fetch("https://api.uemurachiropractic.com/update-block.php", {
        method: "POST",
        body: data,
      });

      toast.success("Update success.");
      await getNavigations();
      setRequestProcessing(false);
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setRequestProcessing(false);
    }
  };

  useEffect(() => {
    getNavigations();
    getLanguages();
  }, [indicator]);

  return (
    <div className="flex">
      <AdminNavigation />
      <div className="flex flex-col gap-4 flex-1 h-screen p-4 bg-gray-200 overflow-auto">
        <h1 className="mr-auto font-bold text-2xl">Navigation Contents</h1>
        <hr className="border-2 border-gray-800" />
        <div className="flex gap-2">
          <button
            className="btn btn-primary"
            onClick={() => navigate("create")}
          >
            New Block
          </button>
        </div>
        {navigationBlocks.length === 0 || languages.length === 0 ? (
          <span className="loading loading-dots loading-lg m-auto"></span>
        ) : (
          navigationBlocks.map((block, blockIndex) => {
            return (
              <div
                className="collapse bg-white overflow-visible"
                key={blockIndex}
              >
                <input type="checkbox" />
                <div className="collapse-title text-xl font-medium">
                  <div className="pb-2 border-b-2 border-gray-900">
                    {(block as any).contents[0].title}
                  </div>
                </div>
                <div className="collapse-content">
                  <div className="flex flex-col gap-4">
                    {requestUpdateImage ? (
                      <div className="flex p-4">
                        <span className="loading loading-dots loading-lg m-auto"></span>
                      </div>
                    ) : (
                      <img
                        className="max-w-60 max-h-60 m-auto"
                        src={`https://api.uemurachiropractic.com/uploads/${
                          (block as any).image
                        }`}
                        alt=""
                      />
                    )}
                    <div role="tablist" className="tabs tabs-lifted">
                      {languages.map((lang, langIndex) => {
                        return (
                          <span
                            role="tab"
                            className={`tab ${
                              languageIndex === langIndex ? "tab-active" : ""
                            }`}
                            onClick={() => setLanguageIndex(langIndex)}
                          >
                            {(lang as any).name}
                          </span>
                        );
                      })}
                    </div>
                    {(block as any).contents.map(
                      (content: any, contentIndex: number) => {
                        return (
                          <form
                            onSubmit={(e) => {
                              handleOnSubmit(e, blockIndex, contentIndex);
                            }}
                            className={`flex flex-col gap-4 ${
                              languageIndex !== contentIndex ? "hidden" : ""
                            }`}
                            key={contentIndex}
                          >
                            <div className="flex-1">
                              <label htmlFor="image">Update Image</label>
                              <input
                                name="image"
                                type="file"
                                className="file-input file-input-bordered w-full"
                                disabled={requestUpdateImage}
                                onChange={(e) => {
                                  handleUpdateImage(e, blockIndex);
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="indicator">Indicator</label>
                              <input
                                name="indicator"
                                className="input input-bordered w-full"
                                defaultValue={content.indicator}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    blockIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="title">Title</label>
                              <input
                                name="title"
                                className="input input-bordered w-full"
                                defaultValue={content.title}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    blockIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="sub_title">Sub-title</label>
                              <input
                                name="sub_title"
                                className="input input-bordered w-full"
                                defaultValue={content.sub_title}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    blockIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="description">Description</label>
                              <textarea
                                name="description"
                                className="textarea textarea-bordered w-full resize-none"
                                rows={8}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    blockIndex,
                                    contentIndex
                                  );
                                }}
                              >
                                {content.description}
                              </textarea>
                            </div>
                            <div className="flex-1">
                              <label htmlFor="button_label">Button label</label>
                              <input
                                name="button_label"
                                className="input input-bordered w-full"
                                defaultValue={content.button_label}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    blockIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="button_text">Button text</label>
                              <input
                                name="button_text"
                                className="input input-bordered w-full"
                                defaultValue={content.button_text}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    blockIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="button_link">Button link</label>
                              <input
                                name="button_link"
                                className="input input-bordered w-full"
                                defaultValue={content.button_link}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    blockIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex gap-2 justify-end">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={requestProcessing}
                              >
                                {requestProcessing ? "Please Wait..." : "Save"}
                              </button>
                            </div>
                          </form>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AdminNavigations;
