import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AdminIndex = () => {
  document.title = "Administrator";

  const navigate = useNavigate();
  const [loginFormProcessing, setLoginFormProcessing] =
    useState<boolean>(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loginFormProcessing) {
      return;
    }

    try {
      setLoginFormProcessing(true);
      const data = new FormData();
      data.append("username", formData.username);
      data.append("password", formData.password);

      const response = await fetch(
        "https://api.uemurachiropractic.com/login.php",
        {
          method: "POST",
          body: data,
        }
      );
      const res = JSON.parse(await response.text());

      if (res.status === "invalid account") {
        toast.error("Invalid username or password.");
        setLoginFormProcessing(false);
        return;
      }

      toast.success("Logged in successfully.");
      navigate("home");
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setLoginFormProcessing(false);
    }
  };

  return (
    <div className="h-screen py-12 bg-gray-200">
      <form
        onSubmit={handleOnSubmit}
        className="flex flex-col gap-4 max-w-sm mx-auto p-4 bg-white shadow-lg"
      >
        <h1 className="font-bold text-2xl text-center">Administrator</h1>
        <label className="input input-bordered flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
          </svg>
          <input
            name="username"
            type="text"
            className="grow"
            placeholder="Username"
            onChange={handleInputChange}
          />
        </label>
        <label className="input input-bordered flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
              clipRule="evenodd"
            />
          </svg>
          <input
            name="password"
            type="password"
            className="grow"
            placeholder="Password"
            onChange={handleInputChange}
          />
        </label>
        <button type="submit" className="btn btn-primary">
          {loginFormProcessing ? "Processing..." : "Log In"}
        </button>
      </form>
    </div>
  );
};

export default AdminIndex;
